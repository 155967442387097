@import "../../custom";

.react-dropdown-select.form-control {
  min-height: 34px;
  border-color: $input-border-color !important;
  border-radius: $border-radius;
  box-shadow: none !important;
  padding: 5px 12px;

  &.react-dropdown-select-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover, &:focus, &:active {
    border-color: $input-border-color;
    box-shadow: none;
  }

  .react-dropdown-select-dropdown-handle {
    margin: auto !important;
    color: $secondary-alt;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .react-dropdown-select-dropdown {
    box-shadow: none;
    border-color: $input-border-color;
    top: 32px;
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .react-dropdown-select-item {
      background-color: #fff;
      padding: 10px 12px;
    }
  }
  .react-dropdown-select-input{
    &:focus{
      outline: none;
    }
  }

  &.custom-select-top {
    &.react-dropdown-select-open {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .react-dropdown-select-dropdown {
      top: unset;
      bottom: 32px;
      border-top: 1px solid #ccc;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
