@import "../../custom";
hr {
  &.hr-text {
    border: none;
    height: 1px;
    background: #ccc;

    &::before {
      content: attr(data-content);
      display: inline-block;
      background: #fff;
      font-weight: bold;
      font-size: 0.85rem;
      color: $primary;
      border-radius: 30rem;
      padding: 0.2rem 2rem;
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      text-align: center;
    }

    &.light {
      &::before {
        background-color: var(--bs-light);
      }
    }
  }
  
}