//
// General


.scroll-rightbar{
  max-height: 500px;
}
.icon-dual {
  color: $icon-dual-color;
  fill: rgba($icon-dual-color,.12);
}
.icon-sm {
  height: 22px;
  width: 22px;
}
.icon-md {
  height: 26px;
  width: 26px;
}
.icon-lg {
  height: 32px;
  width: 32px;
}
hr{
  &.hr-dashed {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px dashed $border_2;
    box-sizing: content-box;
    height: 0;
    background-color: transparent;
    overflow: visible;
  }
}

// Thumb sizes+
.thumb-xxs {
  height: 24px !important;
  width: 24px !important;
  font-size: 10px;
  font-weight: 700;
}
.thumb-xs {
  height: 32px !important;
  width: 32px !important;
  font-size: 12px;
  font-weight: 700;
}
.thumb-sm {
  height: 36px !important;
  width: 36px !important;
  font-size: 14px;
  font-weight: 700;
}
.thumb-md {
  height: 48px;
  width: 48px;
  font-size: 18px;
  font-weight: 700;
}
.thumb-lg {
  height: 60px;
  width: 60px;
  font-size: 24px;
  font-weight: 700;
}
.thumb-xl {
  height: 78px;
  width: 78px;
  font-size: 32px;
  font-weight: 700;
}

.badge{
  line-height: 10px;
}

/* Page titles */

.title-text,
.header-title {
  margin-bottom: 8px;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  font-size: 15px;
  font-weight: 500;
  margin-top: 0;
  color: $header-title;
  text-shadow: 0 0 1px rgba($gray-200, 0.1);
  font-family: $font-secondary;
}
.page-title-box {
  padding: 16px 0;
  .page-title {
    font-size: 18px;
    margin: 0;
    color: $gray-700;
  }

  .breadcrumb {
    font-size: 13px;
    margin-bottom: 0;
    padding: 2px 0;
    background-color: transparent;
  }
}

.dual-border:after {
  content: '';
  height: 3px;
  width: 80px;
  position: absolute;
  top: 20px;
  left: 0;
  border: 1px dotted $primary;
  border-radius: 63px;
}
.dash-info-carousel{
  .carousel-item{
    h2{
      font-weight: 500;
      color: $gray-700;
      font-size: 16px;
    }
  }
  .carousel-control-prev,
  .carousel-control-next{
        width: 30px;
        height: 30px;
        background-color: $gray-100;
        border-radius:50%;
        opacity: 1;
        &:hover {
          opacity: .9;
        }
  }
  .carousel-control-prev {
    left: auto;
    right: 38px;
    top:0;
    border: 1px solid $border;
    .carousel-control-prev-icon {
      background-image: none;
    }
    &:after {
        content: '\f104';
        font-family: 'Line Awesome Free';
        font-weight: 900;
        font-size: .875rem;
        color: $gray-700;
        line-height: 1.875rem;
        position: absolute;
    }
  }
  .carousel-control-next {
    left: auto;
    right: 0;
    top:0;
    border: 1px solid $border;
    .carousel-control-next-icon {
      background-image: none;
      width: 0;
    }
    &:after {
        content: '\f105';
        font-family: 'Line Awesome Free';
        font-weight: 900;
        font-size: .875rem;
        color: $gray-700;
        line-height: 1.875rem;
    }
  }
}
// Prism
:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: $light;
}

// Popover
.popover{
  border-color: $gray-300;
  .popover-header{
    margin-top: 0;
    background-color: $gray-200;
  }
}


.eco-revene-history{
  .nav{
    .nav-item{
      .nav-link.active{
        color: $dark;
        background-color: $light;
      }
    }
  }
}


.icon-demo-content {
  text-align: center;

  i{
    display: block;
    font-size: 30px;
    margin: 0 auto 5px;
    color: $gray-600;
  }

  svg{
    display: block;
    font-size: 30px;
    margin: 0 auto 5px;
    color: $gray-600;
  }


  .col-sm-6 {
    margin-bottom: 40px;

    &:hover {
      i {
        color: $primary;
      }
    }
    &:hover{
      path:nth-of-type(1) {
        fill:$primary;
      }
      path:nth-of-type(2) {
        fill:$text-muted;
      }
    }
  }
}



.icon-xs{
  width: 15px;
  height: 15px;
}
.icon-sm{
  width: 22px;
  height: 22px;
}
.icon-md{
  width: 30px;
  height: 30px;
}
.icon-lg{
  width: 36px;
  height: 36px;
}

.icon-xl{
  width: 42px;
  height: 42px;
}


.br-theme-bars-square .br-widget a{
  background-color: $card-bg;
}
.br-theme-fontawesome-stars-o .br-widget a:after,
.br-theme-css-stars .br-widget a:after,
.br-theme-fontawesome-stars .br-widget a:after{
  color: $gray-300;
}


code[class*="language-"], pre[class*="language-"]{
  text-shadow: none;
  color: $gray-700;
}

.token.operator,
.token.entity,
.token.url,
.language-css
.token.string,
.style .token.string{
  color: $white;
  background: $primary;
}

.icon-demo-content.emoji-icons i {
  display: block;
  font-size: 18px;
  margin: 0 auto 8px;
}




.lightpick{
  box-shadow: none;
  font-family: $font-secondary;
  background-color: $card-bg;
  border: 1px solid $border;
  z-index: 99;
  &.lightpick--inlined{
    display: block;
  }
  .lightpick__month{
    width: 100%;
    background-color: $card-bg;
  }
  .lightpick__day{
    color: $gray-500;
  }
  .lightpick__previous-action,
  .lightpick__next-action,
  .lightpick__close-action{
    background-color: $gray-200;
    padding-bottom: 4px;
  }
  .lightpick__month-title-bar{
    margin-bottom: 22px;
    .lightpick__month-title > .lightpick__select-months{
      color: $gray-700;
      font-weight: 500;
    }
  }

  .lightpick__day-of-the-week{
      color: $gray-700;
      font-weight: 500;
      font-size: 13px;
  }
  .lightpick__day.is-start-date,
  .lightpick__day.is-end-date,
  .lightpick__day.is-start-date:hover,
  .lightpick__day.is-end-date:hover,
  .lightpick__day:not(.is-disabled):hover,
  .lightpick__day.is-today{
    color: $primary;
    background-color: $gray-200;
    background-image: none;
    font-weight: 500;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    margin: 0 auto;
  }
  .lightpick__day.is-today{
    border: 3px solid tint-color($gray-100, 2%);
    box-shadow: 0px 0px 0px 1.25px $gray-200;
    font-weight: 600;
  }
  .lightpick__month-title > .lightpick__select{
    color: $gray-700;
  }
  .lightpick__toolbar{
    .lightpick__previous-action,
    .lightpick__next-action{
      color: $gray-700;
    }
  }
}

  Task Box

.task-box{
  .task-priority-icon{
    i{
      border: 5px solid $light;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      left: -5px;
    }
  }
}


.lang-css{
  .pun,
  .pln{
    color: $dark;
  }
}

.starability-basic,
.starability-slot,
.starability-grow,
.starability-growRotate,
.starability-fade,
.starability-checkmark{
  min-height: auto;
}

.tox-notifications-container{
  display: none;
}

.preview-box {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  .preview-content {
    width: auto;
    height: 50px;
    max-width: 100%;
    border-radius: 5px;
  }
}



div{
  &.hopscotch-bubble{
    background-color: $body-bg;
    color: $body-color;
    border: 1px solid $border;
    h3{
      color: $body-color;
    }
  }
}


.folder-icon div.listree-submenu-heading.collapsed:before {
  content: "\f07b";
  font-family: 'Font Awesome 5 Free';
}

.folder-icon div.listree-submenu-heading.expanded:before {
  content: "\f07c";
  font-family: 'Font Awesome 5 Free';
}

.tobii-zoom__icon{
  display: none;
}
.tobii-zoom{
  display: block !important;
}


// Analytics

.analytic-dash-activity{
  height: 345px;
}

//  Helpdesk

.border-b{
  border-bottom: 1px solid $border;
}
.border-e{
  border-right: 1px solid $border;
  border-bottom: none;
}
@media (min-width: 767.99px) and (max-width: 991.98px){
  .border-bo{
    border-bottom: 1px solid $border;
  }
}
@media (max-width: 767.98px){
  .border-b{
    border-bottom: 1px solid $border;
    border-right:none;
  }
}
.tabulator-row .tabulator-cell div[data-max="100"]{
  background-color: rgba($primary, 0.5) !important;
  height: 3px !important;
}


// Hospital

.timeline-custom {
  position: relative;
  border-left: 1px dashed $border;
  .time-item {
    position: relative;
    padding: 8px 0;
  }
}

.time-item:before,
.time-item-item:before {
  display: table;
  content: " ";
}

.time-item:before {
  position: absolute;
  bottom: 0;
  left: 4px;
  top: 12px;
  width: 8px;
  height: 8px;
  margin-left: -8px;
  background-color: $primary;
  border-radius: 10px;
  content: '';
}

.item-info {
  margin-left: 15px;
}

.country-reports {
  position: absolute;
  .country-data{
    position: relative;
    z-index: 10;
    &:before {
      position: absolute;
      content: '';
      width: 14px;
      height: 14px;
      background: $card-bg;
      left: 50%;
      bottom: -6px;
      transform: translateX(-50%) rotate(45deg);
      z-index: -1;
      border: 1px solid $border;
    }
  }
  &.country-ind{
    top: 43%;
    right: 24%;
  }
  &.country-usa{
    top: 29%;
    left: 18%;
  }
}

.text-slider {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
   >ul {
      white-space: nowrap;
    }
  .move-text {
    animation: moveSlideshow 12s linear infinite;
  }
  @-webkit-keyframes moveSlideshow {
    100% {
        -webkit-transform: translateX(-45%);
        transform: translateX(-45%);
    }
  }
  @keyframes moveSlideshow {
      100% {
          -webkit-transform: translateX(-45%);
          transform: translateX(-45%);
      }
  }
  .mover {
      -webkit-animation: mover 1s infinite alternate;
      animation: mover 1s infinite alternate;
  }
  @-webkit-keyframes mover {
      0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
      }
      100% {
          -webkit-transform: translateY(5px);
          transform: translateY(5px);
      }
  }
  @keyframes mover {
      0% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
      }
      100% {
          -webkit-transform: translateY(5px);
          transform: translateY(5px);
      }
  }
}
.socials {
  a {
    i {
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid $gray-300;
      color: $gray-600;
      transition: all .3s ease-out;
    }
    &:hover i{
      &.fa-facebook-f{
        color: $blue;
        border: 1px solid;
      }
      &.fa-twitter{
        color: $info;
        border: 1px solid;
      }
      &.fa-medium-m{
        color: $danger;
        border: 1px solid;
      }
      &.fa-telegram{
        color: $success;
        border: 1px solid;
      }
      &.fa-btc{
        color: $warning;
        border: 1px solid;
      }
    }
  }
}

//// Ecommerce
//
//.cart-promo {
//  padding: 90px;
//  background-image: url(../../assets/images/products/bg-cart.png);
//  background-position: center center;
//  background-size: cover;
//}
//.total-payment {
//  border: 1px solid $border;
//  background-color: $light;
//  border-radius: 3px;
//}
//
//.shopping-cart,
//.total-payment {
//  .table {
//    tbody {
//      td{
//        padding: 20px 10px;
//        border-top: 0;
//        border-bottom: 1px solid $border;
//      }
//    }
//  }
//}
//
//.checkout-accordion{
//  .accordion-button::after{
//    display: none;
//  }
//}
//
//ul.listree-submenu-items{
//  border-left: 1px dashed $border_2;
//}
//
//
//// RTL
//
//html[dir="rtl"]{
//  .simplebar-offset{
//    right: 0 !important;
//  }
//  .left-sidebar {
//    .navbar-vertical {
//      .navbar-nav {
//        .nav-link[data-bs-toggle=collapse]:after{
//          transform: rotate(180deg);
//        }
//        .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after{
//          transform: rotate(270deg);
//        }
//      }
//    }
//  }
//}
//

//Tobii Lighbox
.tobii__btn svg {
    height: 30px;
    width: auto;
}
.tobii__counter {
    font-size: 16px;
}
.tobii-zoom {
    display: block !important;
}
.tobii-zoom__icon {
    display: none;
}
#grid {
    padding: 0 !important;
}
