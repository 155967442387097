.attach-patient-file {
  max-width: 200px !important;
}

.attach-img {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.patient-modal {
  .modal-header {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .modal-content {
    background-color: rgba(0, 0, 0, .0001) !important;

    img {
      background-color: black;
    }
  }
}

.patient-profile {
  .file-box {
    width: auto;
  }
}