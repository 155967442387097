.auth-page {
  background-image: url('../../assets/images/p-1.png');
  background-size: cover;
  background-position: center center;

  .lang {
    a {
      color: #000;

      &.active {
        font-weight: bold;
      }
    }
  }
}
