.profile {
  .rounded-circle {
    width: 100%;
    object-fit: cover;
  }
}

.submit {
  margin-right: 4px;
}
