.filter-menu {
  position: fixed;
  display: flex;
  align-items: flex-start;
  right: -250px;
  z-index: 9;
  // height: 100vh;
  height: calc(100vh - 120px);
  top: 60px;
  transition: transform 250ms;
  flex-shrink: unset;
  width: unset;


  @media (min-width: 992px) {
    flex: 0 0 auto;
    width: 25%;
    position: relative;
    right: unset;
    top: unset;
    // height: calc(100vh - 60px);
    height: auto;
    margin-bottom: 2rem;

    > button, .btn-close {
      display: none;
    }

    .menu {
      width: 100%;

      h5 {
        display: block !important;
      }
    }
  }

  > button {
    margin-top: 13px;

    @media (max-width: 767.98px) {
      margin-top: 8px;

      i {
        display: inline;
      }
      span {
        display: none;
      }
    }

    @media (min-width: 768px) {
      i {
        display: none;
      }
      span {
        display: unset;
      }
    }
  }

  @media (max-width: 767.98px) {
    width: calc(100% - 60px);
    right: calc(-100% + 110px);
  }

  .menu {
    overflow-y: auto;
    min-width: 250px;
    @media (max-width: 767.98px) {
      width: 100%;
    }

    h5 {
      display: none;
    }
  }

  &.open {
    // right: 0;
    transform: translateX(-250px);
    transition-timing-function: ease-out;

    @media (max-width: 767.98px) {
      transform: translateX(calc(-100% + 50px));
      .btn-close {
        display: block !important;
      }
    }

    @media (min-width: 768px) {
      .btn-close {
        display: none !important;
      }
    }
  }
}
