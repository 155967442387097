@import 'custom';
@import 'scss/app';

body {
  background: #f0f3fb;

  .footer {
    z-index: 999;
    background: #fafafa;
  }

  .custom-radio {
    label {
      margin-bottom: 0.5rem;
      color: #656d9a;
    }
  }

  .react-datepicker-popper {
    z-index: 99;
  }

  .result-date-picker {
    .react-datepicker {
      display: flex;
    }
  }

  .form-group-custom-autocomplete {
    .form-control:focus {
      border-color: #e8ebf3;

      &.expanded {
        border-bottom: none;
        border-radius: 0.25rem 0.25rem 0 0;
      }
    }

    .custom-autocomplete {
      border: 1px solid #e8ebf3;
      border-top: transparent;
      border-radius: 0 0 0.25rem 0.25rem;
      font-size: 0.875rem;
      color: #000;
      background-color: #fff;
      padding: 0.75rem 0;
      z-index: 9;
    }
  }

  .activity {
    .accordion-item:last-child {
      .activity-info {
        &::before {
          border: none;
        }
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .modal {
    .react-dropdown-select.form-control .react-dropdown-select-dropdown {
      width: calc(100% + 2px);
    }
  }
}
