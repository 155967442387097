.leftbar-tab-menu {
  .main-icon-menu {
    .logo {
      visibility: hidden;
    }
  }

  .menu-title {
    padding: 0.5rem 1rem;
    border-bottom: dashed 1px var(--bs-primary);
    color: var(--bs-primary);
  }
}
.enlarge-menu {
  .leftbar-tab-menu {
    .main-icon-menu {
      .logo {
        visibility: visible;
      }
    }
  }
}
