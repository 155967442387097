.input-Widget {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}

.attach-file {
  text-align: center;
  display: flex;
}
