.patient-examinations {
  .accordion-button {
    align-items: flex-start;
    &:not(.collapsed) {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
  .examination-header {
    flex-grow: 1;
  }
}
